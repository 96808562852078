import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";

import { Select } from "../Library/Module";
import { getCity, getCountry, getDealerInfo, getRegions } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";

interface HomeProps {
    link: string;
}

const FindDealer = ({ link }: HomeProps): JSX.Element => {
    const [formData, setFormData] = useState<any>();
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const [regionData, setRegionData] = useState([])
    const [isRegionLoading, setIsRegionLoading] = useState(false)

    const [countryData, setCountryData] = useState([])
    const [isCountryLoading, setIsCountryLoading] = useState(false)

    const [cityData, setCityData] = useState([])
    const [isCityLoading, setIsCityLoading] = useState(false)

    const [dealerInfoData, setDealerInfo] = useState([])
    const [isDealerInfoLoading, setIsDealerInfoLoading] = useState(false)

    useEffect(() => {
        setIsRegionLoading(false)
        const getData = async () => {
            const APIResponse = await getRegions();
            console.log("APIResponse", APIResponse)
            const newRegion: any = []
            APIResponse?.data?.data?.map((item: any) => {
                return newRegion.push({
                    text: item?.title,
                    value: item?._id,
                    id: item?.slug,
                })
            })
            setRegionData(newRegion)
            setIsCountryLoading(false)
            setIsCityLoading(false)
            setIsDealerInfoLoading(false)
            setCountryData([])
            setCityData([])
            setDealerInfo([])
            const data = { "country": null, "city": null }
            setFormData((prevState: any) => ({
                ...prevState,
                ...data,
            }));
        };
        getData();
        setIsRegionLoading(true)
    }, [])

    useEffect(() => {
        console.log("formData?.country", formData?.region)
        if (formData?.region) {
            setIsCountryLoading(false)
            const getData = async () => {
                const query = {
                    "region_id": formData?.region,
                }
                const APIResponse = await getCountry(objectToQueryString(query));
                console.log("APIResponse", APIResponse)
                const newRegion: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return newRegion.push({
                        text: item?.title,
                        value: item?._id,
                        id: item?.slug,
                    })
                })
                setCountryData(newRegion)
                setCityData([])
                setDealerInfo([])
                setIsCityLoading(false)
                setIsDealerInfoLoading(false)
                const data = { "city": null }
                setFormData((prevState: any) => ({
                    ...prevState,
                    ...data,
                }));
            };
            getData();
            setIsCountryLoading(true)
        }
    }, [formData?.region])

    useEffect(() => {
        if (formData?.country) {
            setIsCityLoading(false)
            const getData = async () => {
                const query = {
                    "country_id": formData?.country,
                }
                const APIResponse = await getCity(objectToQueryString(query));
                console.log("APIResponse", APIResponse)
                const newRegion: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return newRegion.push({
                        text: item?.title,
                        value: item?._id,
                        id: item?.slug,
                    })
                })
                setCityData(newRegion)
                setDealerInfo([])
                setIsDealerInfoLoading(false)
            };
            getData();
            setIsCityLoading(true)
        }
    }, [formData?.country])


    useEffect(() => {
        if (formData?.city) {
            setIsDealerInfoLoading(false)
            const getData = async () => {
                const query = {
                    "city_id": formData?.city,
                }
                const APIResponse = await getDealerInfo(objectToQueryString(query));
                console.log("APIResponse", APIResponse)
                setDealerInfo(APIResponse?.data?.data)
            };
            getData();
            setIsDealerInfoLoading(true)
        }
    }, [formData?.city])



    return (
        <>
            <div className="banner banner2">
                <div className="banner_text">
                    <h3 className="changeCase">Find a Dealer</h3>
                </div>
            </div>
            <div className="section contactUsDetails" style={{ paddingBottom: "5px", paddingTop: "15px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <Select
                                inputName={'region'}
                                labelName={"region"}
                                options={regionData ?? []}
                                onChangeSingleCallback={onChangeSingleCallback}
                                selectedItem={formData?.region === null ? null : regionData?.find(
                                    (selected: any) => {
                                        return (selected.value === formData?.region)
                                    }
                                )}
                                required={true}
                                placeholder={"Select Region"}
                                search_option={false}
                                isLoading={true}
                                value={formData?.region}
                                disabled={!isRegionLoading}
                            ></Select>
                        </div>

                        <div className="col-md-3">
                            <Select
                                inputName={'country'}
                                labelName={"country"}
                                options={countryData ?? []}
                                onChangeSingleCallback={onChangeSingleCallback}
                                selectedItem={formData?.country === null ? null : countryData?.find(
                                    (selected: any) => {
                                        return (selected.value === formData?.country)
                                    }
                                )}
                                required={true}
                                placeholder={"Select Region"}
                                search_option={false}
                                isLoading={true}
                                value={formData?.region}
                                disabled={!isCountryLoading}
                            ></Select>
                        </div>

                        <div className="col-md-3">
                            <Select
                                inputName={'city'}
                                labelName={"city"}
                                options={cityData ?? []}
                                onChangeSingleCallback={onChangeSingleCallback}
                                selectedItem={formData?.city === null ? null : cityData?.find(
                                    (selected: any) => {
                                        return (selected.value === formData?.city)
                                    }
                                )}
                                required={true}
                                placeholder={"Select Region"}
                                search_option={false}
                                isLoading={true}
                                value={formData?.region}
                                disabled={!isCityLoading}
                            ></Select>
                        </div>
                    </div>

                </div>
            </div>
            <div className="dealerInfoContainer">
                <div className="container">
                    <div className="row">
                        {dealerInfoData?.map((item: any) => {
                            return (
                                <div className="col-md-4">
                                    <div className="inner">
                                        <h4>{item?.title}</h4>
                                        <p><label>Contact No.</label>{item?.contact}</p>
                                        <p><label>Email Id</label>{item?.email_id}</p>
                                        <p><label>Address</label>{item?.description}</p>
                                        <p><label>Google Map</label><a target="_blank"  href={item?.google_map} rel="noreferrer">Get Direction</a></p>
                                    </div>
                                </div>
                            )
                        })}

                        {!formData?.region || !formData?.country || !formData?.city ?
                            <div className="note">
                                Please select all required fields to see the dealers information.
                            </div>
                            : null}

                        {isDealerInfoLoading && dealerInfoData?.length === 0 && (
                            <div className="note">
                                No Data Found
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default FindDealer;
